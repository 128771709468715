import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import { useSelector } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { setUpdatedModule, updateCompanySettingsAsyncThunk } from '../../../store/modules/moduleSlice';
import { useDispatch } from 'react-redux';

import { Message } from 'primereact/message';
import { useLocation, useNavigate } from 'react-router-dom';

import LucaCompanyReq from './components/LucaCompanyReq';
import LucaDocCode from './components/LucaDocCode';
import Loading from '../../Loading';

import { updateCompanyReq } from '../../../store/modules/lucaSlice';

import { LucaRequest } from './utils/lucaRequest';
import { clearAllLuca, setCodeList } from '../../../store/modules/lucaSlice';
import { getCompanyForDropdown, getPeriodForDropdown, deleteCompanyIsHere } from './utils/util';
import ProgramReq from './components/ProgramReq';
import { getAllWebServicesByModuleId } from '../../../service/request/moduleRequest';
import { getCompaniesByUserAsyncThunk } from '../../../store/company/userCompanySlice';
import { updateLucaPassword } from '../../../service/request/moduleRequest';
import { portalDefterCheck } from '../datasoft/utils/DatasoftRequest';
import { sendCaptcha, loginLuca, getCompanyAndPeriodList } from './utils/lucaRequest';

export default function UpdateLuca() {
    const { updatedModule } = useSelector((state) => state.modules);
    const { selectedCompany: userSelectedCompany } = useSelector((state) => state.userCompany);
    const { companyReq, codeList, programReq } = useSelector((state) => state.luca);

    const [response, setResponse] = useState(null);
    const [companyList, setCompanyList] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);

    const [periodList, setPeriodList] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(null);

    const [loading, setLoading] = useState(false);

    const [isLoginLuca, setIsLoginLuca] = useState(false);
    const [loginError, setLoginError] = useState(null);

    const [dialogVisible, setDialogVisible] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [lucaCompanies, setLucaCompanies] = useState([]);

    const [pending, setPending] = useState(false);

    const [updateCompanyDialog, setUpdateCompanyDialog] = useState(false);

    const [webServicesList, setWebServicesList] = useState([]);
    const [username, setUsername] = useState(null);
    const [password, setPassword] = useState(null);
    const [selectedWebService, setSelectedWebService] = useState(null);

    const [cookies, setCookies] = useState(null);
    const [jSessionId, setJSessionId] = useState(null);
    const [wMonId, setWMonId] = useState(null);
    const [captcha, setCaptcha] = useState(null);
    const [captchaImage, setCaptchaImage] = useState(null);
    const [captchaVisible, setCaptchaVisible] = useState(false);
    const [captchaLoading, setCaptchaLoading] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const getWebServices = async () => {
        const res = await getAllWebServicesByModuleId(updatedModule?.id);
        setWebServicesList(res.data.data);
    };

    useEffect(() => {
        let path = location.pathname.split('/')[1];
        let module = userSelectedCompany?.modules.find((item) => item.title === path);
        dispatch(setUpdatedModule(module));
    }, [location.pathname, userSelectedCompany?.modules, dispatch, updatedModule]);

    useEffect(() => {
        if (updatedModule) {
            setLucaCompanies(updatedModule.setting.settings.program.lucaCompanies);
        }
    }, [updatedModule]);

    useEffect(() => {
        if (loginError) {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: loginError, life: 3000 });

            setTimeout(() => {
                setLoginError(null);
            }, 3000);
        }
    }, [loginError]);

    const toast = useRef(null);

    const handleCaptcha = (data) => {
        return `data:image/png;base64,${data?.captchaImage}`;
    };

    const handleCaptchaSubmit = async () => {
        setLoading(true);
        setCaptchaLoading(true);
        try {
            const res = await sendCaptcha({ cookie: cookies, captcha: captcha });
            setJSessionId(res.data.data.jSessionId);
            setWMonId(res.data.data.wMonId);
            const companyAndPeriodList = await getCompanyAndPeriodList(res.data.data.jSessionId, res.data.data.wMonId);
            setResponse(companyAndPeriodList.data.data);
            setCompanyList(deleteCompanyIsHere(getCompanyForDropdown(companyAndPeriodList.data.data), lucaCompanies));
            setIsLoginLuca(true);
            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Captcha başarıyla doğrulandı', life: 3000 });
            }, 1);
        } catch (error) {
            setCaptchaVisible(false);
            setTimeout(() => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message, life: 3000 });
            }, 1);
        }
        setCaptchaLoading(false);
        setLoading(false);
        setCaptcha(null);
        setCaptchaImage(null);
        setCaptchaVisible(false);
        setCookies(null);
    };

    const login = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const res = await loginLuca(programReq.username, programReq.password, programReq.customerNumber);
            setCaptchaVisible(true);
            setCookies(res.data.data.cookie);
            setCaptchaImage(handleCaptcha(res.data.data));
            getWebServices();
        } catch (error) {
            setTimeout(() => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: error?.response?.data?.message, life: 3000 });
            }, 1);
        }
        setLoading(false);
    };

    const handleChangeCompany = (e) => {
        setSelectedCompany(e.value);
        setPeriodList(getPeriodForDropdown(response, e.value));
    };

    const save = async () => {
        const data = {
            companyId: userSelectedCompany.id,
            moduleId: updatedModule.id,
            settings: {
                moduleId: updatedModule.id,
                title: updatedModule.title,
                program: {
                    _id: updatedModule.setting.settings.program._id,
                    title: updatedModule.setting.settings.program.title,
                    requirements: [
                        {
                            username: programReq.username,
                            password: programReq.password,
                            customerNumber: programReq.customerNumber
                        }
                    ],
                    lucaCompanies: lucaCompanies
                }
            }
        };

        setPending(true);
        await dispatch(updateCompanySettingsAsyncThunk(data));
        await dispatch(getCompaniesByUserAsyncThunk());
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Modül güncellendi', life: 1500 });

        setTimeout(() => {
            window.location.reload();
        }, 1500);
    };

    const handleCreateCompany = () => {
        const company = response.find((item) => item.company.value === selectedCompany);
        const period = company.periods.find((item) => item.value === selectedPeriod);
        const webServiceRequirements = webServicesList.find((item) => item.id === selectedWebService);

        const data = {
            companyName: company.company.label,
            companyCode: company.company.value,
            periodId: String(period.value),
            periodTitle: String(period.label),
            eDefterSubeAdi: companyReq[0].value,
            eDefterSubeKodu: companyReq[1].value,
            taxId: companyReq[2].value,
            belgeTurleri: codeList,
            webService: {
                _id: webServiceRequirements.id,
                title: webServiceRequirements.title,
                url: webServiceRequirements.url,
                requirements: {
                    username: username,
                    password: password
                }
            }
        };

        setLucaCompanies([...lucaCompanies, data]);

        setCompanyList(companyList.filter((item) => item.value !== selectedCompany));
        setDialogVisible(false);
        setSelectedCompany(null);
        setSelectedPeriod(null);
        setPeriodList(null);
        setUsername('');
        setPassword('');
        setSelectedWebService(null);
        dispatch(clearAllLuca());
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Eklendi', life: 1500 });
    };

    const handleDeleteCompany = (rowData) => {
        setLucaCompanies(lucaCompanies.filter((item) => item.companyCode !== rowData.companyCode));
        setCompanyList([...companyList, { title: rowData.companyName, value: rowData.companyCode }]);
        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Silindi', life: 1500 });
    };

    const checkCreate = () => {
        return selectedCompany && selectedPeriod && companyReq[2].value && username && password && selectedWebService;
    };

    const actionCodeTemplate = (rowData) => {
        return (
            <>
                <Button icon="pi pi-pencil" tooltip="Düzenle" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} rounded outlined severity="primary" onClick={() => handleChangeUpdateCompany(rowData)} />
                <Button icon="pi pi-trash" tooltip="Sil" tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }} className="ml-2" rounded outlined severity="danger" onClick={() => handleDeleteCompany(rowData)} />
            </>
        );
    };

    const header = (
        <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
            <h4 className="m-0">Şirketler</h4>
            <div>
                <Button label="Şirket Ekle" icon="pi pi-plus-circle" className="p-button-primary text-white mr-4 mb-0" onClick={() => setDialogVisible(true)} disabled={!isLoginLuca} />
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Ara..." />
                </span>
            </div>
        </div>
    );

    const handleChangeUpdateCompany = (rowData) => {
        setSelectedCompany(Number(rowData.companyCode));
        dispatch(setCodeList(rowData.belgeTurleri));

        dispatch(updateCompanyReq({ title: 'E Defter Şube Adı', value: rowData.eDefterSubeAdi || '' }));
        dispatch(updateCompanyReq({ title: 'E Defter Şube Kodu', value: rowData.eDefterSubeKodu || '' }));
        dispatch(updateCompanyReq({ title: 'Vergi Numarası', value: rowData.taxId }));

        let period = [];

        response.forEach((item) => {
            if (item.company.value === rowData.companyCode) {
                item.periods.forEach((p) => {
                    period.push({
                        title: p.label,
                        value: p.value
                    });
                });
            }
        });

        setSelectedWebService(rowData.webService?._id);
        setUsername(rowData.webService?.requirements?.username);
        setPassword(rowData.webService?.requirements?.password);

        setPeriodList(period);
        setSelectedPeriod(Number(rowData.periodId));
        setUpdateCompanyDialog(true);
    };

    const handleUpdateCompany = () => {
        const company = lucaCompanies.find((item) => item.companyCode === String(selectedCompany));
        const webServiceRequirements = webServicesList.find((item) => item.id === selectedWebService);

        const data = {
            companyName: company.companyName,
            companyCode: company.companyCode,
            periodId: String(selectedPeriod),
            periodTitle: String(periodList.find((item) => item.value === selectedPeriod).title),
            eDefterSubeAdi: companyReq[0].value,
            eDefterSubeKodu: companyReq[1].value,
            taxId: companyReq[2].value,
            belgeTurleri: codeList,
            webService: {
                _id: webServiceRequirements.id,
                title: webServiceRequirements.title,
                url: webServiceRequirements.url,
                requirements: {
                    username: username,
                    password: password
                }
            }
        };

        setLucaCompanies(
            lucaCompanies.map((item) => {
                if (item.companyCode === String(selectedCompany)) {
                    return data;
                }
                return item;
            })
        );

        setCompanyList(companyList.filter((item) => item.value !== String(selectedCompany)));

        setSelectedCompany(null);
        setSelectedPeriod(null);
        setPeriodList(null);
        dispatch(clearAllLuca());

        setUpdateCompanyDialog(false);
        setSelectedWebService(null);
        setUsername('');
        setPassword('');

        toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şirket Güncellendi', life: 1500 });
    };

    if (loading) {
        return <Loading loading={loading} onChangeLoading={() => setLoading(false)} />;
    }

    const hideDialog = () => {
        setUpdateCompanyDialog(false);
        setDialogVisible(false);
        setSelectedCompany(null);
        setSelectedPeriod(null);
        setPeriodList(null);
        setUsername('');
        setPassword('');
        setSelectedWebService(null);
        dispatch(clearAllLuca());
    };

    const changeWebServices = (e) => {
        setUsername('');
        setPassword('');
        setSelectedWebService(e.value);
    };

    const isDisabled = () => {
        return programReq.username && programReq.password && programReq.customerNumber;
    };

    const handleUpdatePassword = async (data) => {
        setLoading(true);
        try {
            await updateLucaPassword(data);
            await dispatch(getCompaniesByUserAsyncThunk());
            setLoading(false);
            setTimeout(() => {
                toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Şifre Güncellendi', life: 1500 });
            }, 1);
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        } catch (err) {
            setTimeout(() => {
                toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Şifre Güncellenemedi', life: 1500 });
            }, 1);
        }
    };

    const handleCheckPortal = async () => {
        const webService = webServicesList.find((item) => item.id === selectedWebService);

        const data = {
            url: webService.url,
            username: username,
            password: password
        };

        const res = await portalDefterCheck(data);

        if (res.data.success) {
            toast.current.show({ severity: 'success', summary: 'Başarılı', detail: 'Web servis kullanıcı bilgileriniz doğrudur', life: 3000 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Hata', detail: 'Web servis kullanıcı bilgileriniz hatalıdır', life: 3000 });
        }
    };
    return (
        <div className="card">
            <Toast ref={toast} />
            <div>
                <div className="flex flex-column md:flex-row">
                    <div className="w-full">
                        <ProgramReq
                            req={programReq ? programReq : updatedModule?.setting.settings.program.requirements[0]}
                            title={updatedModule?.setting.settings.program.title}
                            onLogin={login}
                            isLogin={isLoginLuca}
                            onUpdatePassword={handleUpdatePassword}
                        />
                    </div>
                </div>
            </div>
            <Dialog
                header="Şirket Ekle"
                visible={dialogVisible}
                style={{ width: '50vw' }}
                maximizable
                modal
                onHide={() => hideDialog()}
                footer={<Button label="Oluştur" icon="pi pi-check" className="p-button-info mt-3" onClick={() => handleCreateCompany()} disabled={!checkCreate()} />}
            >
                <div className="mt-4">
                    {isLoginLuca && (
                        <>
                            <label className="mb-2 block">Şirketler</label>
                            <Dropdown
                                dataKey="_id"
                                value={selectedCompany}
                                onChange={handleChangeCompany}
                                options={companyList}
                                optionLabel="title"
                                placeholder="Şirket Seçiniz"
                                filter
                                className="w-full mb-4"
                                emptyMessage="Şirket Bulunamadı"
                                appendTo="self"
                            />
                            {periodList && (
                                <div className="mb-3">
                                    <label className="mb-2 block">Periyotlar</label>
                                    <Dropdown
                                        dataKey="id"
                                        value={selectedPeriod}
                                        onChange={(e) => setSelectedPeriod(e.value)}
                                        options={periodList}
                                        optionLabel="title"
                                        placeholder="Periyot Seçiniz"
                                        filter
                                        className="w-full mb-2"
                                        emptyMessage="Periyot Bulunamadı"
                                        appendTo="self"
                                    />
                                </div>
                            )}
                        </>
                    )}

                    <div>
                        <div className="w-full mb-2">
                            <label>Web Servis Programları</label>
                        </div>
                        <div>
                            <Dropdown
                                dataKey="id"
                                value={selectedWebService}
                                onChange={(e) => changeWebServices(e)}
                                options={webServicesList?.map((item) => ({ value: item.id, label: item.title })) || []}
                                optionLabel="label"
                                placeholder="Web Servis Seçiniz"
                                filter
                                className="w-full mb-3"
                                emptyMessage="Web Servis Bulunamadı"
                                appendTo="self"
                            />
                        </div>
                    </div>
                    {selectedWebService && (
                        <div className="my-2">
                            <div className="w-full mb-2">
                                <label>Web Servis Kullanıcı Adı</label>
                            </div>
                            <div className="w-full">
                                <InputText className="w-full mb-4" type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Kullanıcı Adı" required />
                            </div>
                            <div className="w-full mb-2">
                                <label>Web Servis Şifre</label>
                            </div>
                            <div className="flex w-full align-items-center gap-3">
                                <InputText className="w-full" type="text" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Şifre" required />
                                <Button label="Şifreyi Kontrol Et" className="flex-shrink-0" onClick={handleCheckPortal} />
                            </div>
                        </div>
                    )}

                    <LucaCompanyReq />
                    <Message severity="info" className="mt-2" text="Kişisel kullandığınız farklı bir belge kodu varsa lütfen aşağıdan eşleştirerek değiştiriniz." />
                    <LucaDocCode />
                </div>
            </Dialog>
            {lucaCompanies && isLoginLuca && (
                <DataTable
                    value={lucaCompanies}
                    paginator
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25]}
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="{totalRecords} şirketten {first} ila {last} arası gösteriliyor"
                    emptyMessage="Şirket Bulunamadı"
                    className="my-6"
                    header={header}
                    globalFilter={globalFilter}
                >
                    <Column field="companyName" header="Şirket İsmi" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="periodTitle" header="Periyot" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column field="webService.title" header="Web Servis Adı" sortable style={{ minWidth: '16rem' }}></Column>
                    <Column header="İşlemler" body={actionCodeTemplate} exportable={false} style={{ minWidth: '12rem' }}></Column>
                </DataTable>
            )}
            <Dialog
                visible={captchaVisible}
                onHide={() => setCaptchaVisible(false)}
                header="Doğrulama Kodu"
                modal
                style={{ width: '450px' }}
                footer={
                    <>
                        <Button severity="danger" onClick={() => setCaptchaVisible(false)}>
                            Kapat
                        </Button>
                        <Button severity="success" disabled={!captcha} className="text-white" loading={captchaLoading} onClick={() => handleCaptchaSubmit()} label="Doğrula" />
                    </>
                }
            >
                <div className="flex align-items-center gap-2">
                    <p className="m-0 w-full gap-4 flex flex-column">
                        {captchaImage && <img src={captchaImage} alt="captcha" className="w-full" />}
                        Lütfen doğrulama kodunu giriniz.
                        <InputText
                            placeholder="Doğrulama Kodu"
                            onChange={(e) => setCaptcha(e.target.value)}
                            className="w-full"
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && captcha) {
                                    handleCaptchaSubmit();
                                }
                            }}
                        />
                    </p>
                </div>
            </Dialog>
            <Dialog
                header="Şirket Güncelle"
                visible={updateCompanyDialog}
                style={{ width: '50vw' }}
                modal
                onHide={() => hideDialog()}
                footer={<Button label="Güncelle" icon="pi pi-check" className="p-button-info mt-3" onClick={() => handleUpdateCompany()} disabled={!checkCreate()} />}
            >
                <div className="mt-4">
                    <label className="mb-2 block">Periyotlar</label>
                    <Dropdown
                        dataKey="id"
                        value={selectedPeriod}
                        onChange={(e) => setSelectedPeriod(e.value)}
                        options={periodList}
                        optionLabel="title"
                        placeholder="Periyot Seçiniz"
                        filter
                        className="w-full mb-2"
                        emptyMessage="Periyot Bulunamadı"
                        appendTo="self"
                    />

                    <div className="mt-3">
                        <div className="w-full mb-2">
                            <label>Web Servis Programları</label>
                        </div>
                        <div>
                            <Dropdown
                                dataKey="id"
                                value={selectedWebService}
                                onChange={(e) => changeWebServices(e)}
                                options={webServicesList?.map((item) => ({ value: item.id, label: item.title })) || []}
                                optionLabel="label"
                                placeholder="Web Servis Seçiniz"
                                filter
                                className="w-full mb-3"
                                emptyMessage="Web Servis Bulunamadı"
                                appendTo="self"
                            />
                        </div>
                    </div>
                    {selectedWebService && (
                        <div className="my-2">
                            <div className="w-full mb-2">
                                <label>Web Servis Kullanıcı Adı</label>
                            </div>
                            <div className="w-full">
                                <InputText className="w-full mb-4" type="text" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Kullanıcı Adı" required />
                            </div>
                            <div className="w-full mb-2">
                                <label>Web Servis Şifre</label>
                            </div>
                            <div className="flex w-full align-items-center gap-3">
                                <InputText className="w-full" type="text" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Şifre" required />
                                <Button label="Şifreyi Kontrol Et" className="flex-shrink-0" onClick={handleCheckPortal} />
                            </div>
                        </div>
                    )}
                    <LucaCompanyReq />
                    <Message severity="info" className="mt-2" text="Kişisel kullandığınız farklı bir belge kodu varsa lütfen aşağıdan eşleştirerek değiştiriniz." />
                    <LucaDocCode />
                </div>
            </Dialog>
            {isLoginLuca && (
                <div className="text-center mt-5">
                    <Button label="Kaydet" icon="pi pi-check" className="p-button-success text-white p-mr-2 px-6" onClick={() => save()} disabled={!isDisabled()} loading={pending} />
                </div>
            )}
            {/*  <ModuleChangeDialog visible={updateDialog} onCloseDialog={() => setUpdateDialog(false)} /> */}
        </div>
    );
}
